.hidescroll {
    overflow: hidden!important;
  }

  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  
  ::-webkit-scrollbar-thumb {
    background: rgb(62, 62, 62);
  }
  
  ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.2);
  }
  .display {
    opacity: 1!important;
  }
  .containery {
    width: auto;
    max-width: 1300px;
    padding-right: var(--bs-gutter-x,.75rem);
    padding-left: var(--bs-gutter-x,.75rem);
    margin-right: auto;
    margin-left: auto;
  }
  .contact .rowy {
    width: 100%;
  }
  .rowy {
    display: flex;
    z-index: 1;
  }
  .img-fluide {
    max-width: 70%;
    width: 100%;
  }
  .text-center {
    text-align: center;
  }
  .bgred {
    background-color: red;
  }
  .btny {
    outline: 0;
    border: 0;
    font-size: 20px;
    border-radius: 5px;
    text-decoration: none;
    color:white;
    font-weight: 500;
    z-index: 1;
    background-color: #3a8d94;
    padding:  10px 50px;
    margin: 0 0.5rem;
  }
  
  .col-md-12 {
    width: 100%;
  }
  .col-md-6 {
    width: 49.9%;
  }
  .col-md-3 {
    margin: 0 1rem!important;
    width:100%;
  }
  
  .col-md-4 {
    width:33.3%;
  }
  .col-md-4:first-child {
    margin-right: 1rem;
  
  }
  .col-md-4:last-child {
    margin-left: 1rem;
  
  }
  
  .x {
    margin: 0rem 1rem!important;
    width:33.3%;
  }
  /* Navbar */
  .navopened {
    background-color: #010606!important;
  }
  .navopened .logo {
    color: #fafafa!important;
  }
  .navopened ul li a {
    color: #fafafa!important;
  }
  .navopened .burger {
    background-color: #010606!important;
  }
  .navbary {
    transition: background-color 0.4s;
    position: fixed;
    top: 0;
    width: 100% ;
    padding: 0.5rem;
    background-color: transparent;
    z-index: 2;
    backdrop-filter: blur(5px);
  }
  .navbary .logo {
    transition: all 0.2s;
  
    align-items: center;
    align-content: center;
    color: rgb(243, 243, 243);
    letter-spacing: 2px;
    font-weight: 700;
    margin: 0;
    font-size: 28px;
    /* padding-top: 10px; */
  }
  .navbary ul { 
    transition: all 0.2s !important;
    list-style: none;
    margin-bottom: 0px;
    margin-left: auto;
  }
  .navbary ul li {
    margin: 0 1rem;
    display: inline-block;
  }
  .navbary ul li a {
    transition: all 0.2s !important;
    font-size: 20px !important;
    cursor: pointer !important;
  
    text-decoration: none !important;
    color: rgb(243, 243, 243) !important;
    font-weight: 800 !important;
  
  }
  .button {
    cursor: pointer;
    transition: transform 0.2s linear;
    position: fixed;
    transform:translateX(300%);
    top: 20px;
    right: 15px;
    z-index: 1;
  }
  .burger {
    transition: all 0.2s;
  
    margin-bottom: 0.45rem;
    width: 25px;
    height: 3px;
    background-color: white;
  }
  /* Header */
  video {
    top: 0;
    object-fit: cover;
    width: 100%;
    height: 100vh;
    position: absolute;
    z-index: -1;
  }
  
  .headerbg {
    position: absolute;
    width: 100%;
    /* height: 50vh; */
    z-index: 0;
    top: 0;
    background-color: black;
    opacity: 0.7;
  
  }
  header {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  }
  header h1 {
    z-index: 1;
    color: white;
    font-weight: bold;
    font-size: 1.5rem;
  }
  .opened {
    transform: translateX(0%)!important;
  }
  /* main */
  .main-title {
    font-size: 48px;
    color: #3a8d94;
  }
  .about-text {
    font-size: 18px;
    font-weight: 500;
  }
  main {
    padding: 3rem 0;
    background-color: #fafafa;
    width: 100%;
  }
  /* footer */
  .text-white {
    color: white;
  }
  .footer-top {
    margin: 0;
    padding: 0.1rem 0;
    background-color: #3a8d94;
  }
  .footer-top p {
    font-size: 18px;
  }
  .footer-text {
    margin-top: 2.5px;
  
    font-size: 18px;
    color:#919191;
  }
  footer {
    width: 100%;
    height: auto;
    margin: 0;
    padding: 1rem 0;
    background-color:#010606;
  }
  .side1 {
    width: 50%;
  }
  .side2 {
    width: 50%;
  
  }
  footer .col-md-3 {
    height: 100%;
  }
  footer .logo {
    transition: all 0.2s;
    margin-bottom: 0;
    align-items: center;
    align-content: center;
    color: rgb(243, 243, 243);
    letter-spacing: 2px;
    font-weight: 400;
  
    font-size: 28px;
  }
  .logo a{
    text-decoration: none;
    color: #fafafa;
    
  }
  .footer-title {
    color: #fafafa;
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 0;
    word-wrap:initial;
  }
  footer ul {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
    margin-top: 2.5px;
  }
  footer ul li a {
    text-decoration: none !important;
    color: #919191 !important;
    font-size: 18px !important;
    font-weight: 500 !important;
  }
  .gotop {
    transition: all 0.2s;
    background-color: #3a8d94;
    position: fixed;
    border-radius: 25px;
    padding: 1rem;
    border: 0;
    opacity: 0;
    outline: 0;
    cursor: pointer;
    font-weight: 1000;
    box-shadow: 0px 0px 5px 0.2px #000000;
    color: white;
    bottom: 20px;
    right: 15px;
    
  }
  /* Services */
  .card-title {
    color: #3a8d94;
    font-size: 24px;
  }
  .p-3 {
    padding: 0.2rem 1rem;
  }
  .card-text {
    padding: 0rem 0rem 1rem 0rem;
    font-weight: 500;
    /* font-size: 18px; */
  
  }
  
  .card {
    cursor: pointer;
    border-radius: 10px;
    width: 100%;
    transition: all 0.2s;
    background-color: white;
    box-shadow: 5px 5px 5px 5px #e5e5e5;
  }
  .card:hover {
    transform:scale(1.03);
  }
  
  .services .col-md-4 {
    margin: 1rem 1rem 1rem 1rem;
  
  
  }
  ul li a {
    cursor: pointer;
  }
  .about {
    margin-bottom: 15rem;
  }
  .card-cover {
    padding: 2rem 0rem;
    border-radius: 15px;
    height: auto;
    width: 100%;
  }
  .s {
    width: 100%;
    margin: 0 1rem!important;
  }
  
  .contact-input {
    color:white;
    border-radius: 5px;
    padding: 15px 0px 10px 5px;
    font-size: 20px;
    width: 100%;
    outline: 0;
    border: 0;
    background-color: rgba(0, 0, 0, 0.76);
  
  }
  .form-btn {
    border: 0;
    outline: 0;
    cursor: pointer;
    font-size: 20px;
    border-radius: 5px;
    text-decoration: none;
    color: white;
    width: 100%;
    font-weight: 500;
    z-index: 1;
    background-color: #3a8d94;
    padding: 10px 50px;
  
    transition: background-color 0.2s;
  }
  .contact-textarea {
    color:white;
    border-radius: 5px;
    padding: 15px 0px 10px 5px;
    font-size: 20px;
    width: 100%!important;
    min-width: 100%!important;
    max-width: 100%!important;
    height: 300px;
    min-height: 300px;
    max-height: 600px;
  
    outline: 0;
    border: 0;
    background-color: rgba(0, 0, 0, 0.76);
  
  }
  .about-section {
    min-height: 37.875rem;
    margin: 0 0 3.3125rem;
    padding: 5rem 6rem 5rem 5rem;
    background-color: #095352;
  }

  .about-h2 {
    margin: 2rem;
  }
  @media only screen and (max-width: 600px) {
    .about-section {
      padding: 0px;
      display: block;
    }
  }
  @media (max-width: 1300px) {
    .containery {
      max-width: 1300px;
    }
  }
  
  @media (max-width: 992px) {
    .col-md-4 {
      width: 49.9%;
    }
    .navbary ul {
      margin-left: 5rem;
    }
    .containery {
      max-width: 960px;
    }
    .col-md-3 {
      width: 100%;
    }
    .side1 .rowy {
      display: inline-block;
      width: 100%;
    }
    .side2 .rowy {
      display: inline-block;
      width: 100%;
  
    }
  }
  
  @media (max-width: 768px) { 
    header h1 {
      text-align: center;
      font-size: 44px!important;
    }
    .containery {
      max-width: 720px;
    }
    .navbary ul { 
      margin-left: 0rem;
      list-style: none;
    }
    .col-md-4 {
      width: 55.9%!important;
    }
  
  }
  @media (max-width: 576px) { 
    .col-md-4:first-child {
      margin-right: 0;
    
    }
    .col-md-4:last-child {
      margin-left: 0;
    
    }
    .services .col-md-4 {
      margin: 2rem auto!important;
      width: 87.9%!important;
  
    }
    .col-md-4 {
      
      width: 100%!important;
    }
    .contact .col-md-4 {
      
      margin: none!important;
      }
    .col-md-3 {
      margin: 0!important;
    }
  
    footer .rowy {
      display: block;
    }
    .side1 {
      width: 100%;
    }
    .side2 {
      width: 100%;
    
    }
     .navopened ul {
      top: 55px!important;
  
     }
    .navopened {
      background-color: #010606!important;
    }
    .navopened .logo {
      color: #fafafa!important;
    }
    .navopened ul li a {
      color: #010606!important;
    }
    .navopened .burger {
      background-color: #fafafa!important;
    }
    .containery {
      width: auto;
    }
    header h1 {
  
      font-size: 44px;
    }
    .btny {
  
      padding:  10px 30px;
  
    }
    .containery {
        max-width: 540px; 
    }
  
    .button {
      transform:translateX(0);
  
    }
  
    .navbary .logo {
      z-index: 3;
      color: #fafafa;
      padding-top: 0;
    }
  
    .burger {
      background-color: #fafafa;
    }
    .navbary {
      backdrop-filter: none;
      padding: 10px 0;
    }
    .navbary ul { 
      backdrop-filter: blur(20px);
  
      z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
      height: 100vh;
      width: 100%;
      list-style: none;
      padding: 0;
      text-align: center;
      margin: 0;
      padding-top: 5rem;
      transform: translateX(-100%);
    }
  
    .navbary ul li {
      margin: 2rem 0rem;
      display: block;
    }
    .navbary ul li a {
        cursor: pointer;
      font-size: 20px;
      text-decoration: none;
      color: #fafafa;
      font-weight: 800;
    
    }
    .col-md-6 {
      width: 100%;
    }
    .rowy {
      display: inline-block;
    }
  }
  
  .mb-2 {
    margin-bottom: 2rem!important;
  }
  .mb-1 {
    margin-bottom: 1rem!important;
  }